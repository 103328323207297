import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ThemeProvider } from './ThemeContext';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <ThemeProvider>
    <App />
  </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export function changeAssets(theme) {
  const favicon = document.getElementById('dynamic-favicon');
  const appleIcon = document.getElementById('apple-touch-icon');
  favicon.href = `${process.env.PUBLIC_URL}/${theme}/favicon.ico`;
  appleIcon.href = `${process.env.PUBLIC_URL}/${theme}/logo192.png`;
}
