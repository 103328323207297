import React from 'react';
import ProjectCard from './ProjectCard';
import { projects } from './ProjectsData';

const ProjectSection = () => {
  return (
    <section className="bg-primary-100 py-12">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold text-center mb-8">My Projects</h2>
        <div className="flex flex-row">
          {projects.map((project) => (
            <ProjectCard
              key={project.id}
              title={project.title}
              description={project.description}
              imageUrl={project.imageUrl}
              link={project.link}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ProjectSection;
