import React from "react";

function Projects() {
  return (
    <section>
      <h2>My Projects</h2>
      {/* Your project content */}
    </section>
  );
}

export default Projects;
