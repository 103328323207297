import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Header = ({ theme }) => {
  const [isHeaderVisible, setHeaderVisible] = useState(false);

  useEffect(() => {
    let isMouseOverTop = false;

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;

      if (!isMouseOverTop && scrollPosition < windowHeight) {
        setHeaderVisible(false);
      } else {
        setHeaderVisible(true);
      }
    };

    const handleMouseMove = (event) => {
      const mouseY = event.clientY;
      isMouseOverTop = mouseY < 10;

      if (isMouseOverTop && !isHeaderVisible) {
        setHeaderVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [isHeaderVisible]);

  return (
    <header
      className={`bg-[#FFFFFF] fixed w-full text-gray-800 p-4 flex justify-between items-center transition-all duration-500 shadow-md ${
        isHeaderVisible
          ? "top-0 opacity-100 transform translate-y-0"
          : "opacity-0 transform -translate-y-full"
      }`}
    >
      <div className="flex items-center">
        <Link to="/" className="hover:underline flex flex-row justify-center items-center">
        <img
          src={`${process.env.PUBLIC_URL}/${theme}/logo192.png`}
          alt="logo"
          className="h-10 w-10"
        />
        <span className="ml-2 text-xl font-bold">Josephine Nguyen</span>
        </Link>
      </div>
      <nav className="flex space-x-4">
        <Link to="/resume" className="hover:underline">
          Resume
           {/* & CV */}
        </Link>
        <Link to="/projects" className="hover:underline">
          My Projects
        </Link>
        <Link to="/about" className="hover:underline">
          About Me
        </Link>
      </nav>
    </header>
  );
};

export default Header;
