function ScrollIndicator() {
  return (
    <div className="mt-24 bottom-0 absolute">
      <p className="text-gray-500 text-sm mb-2">Explore my work</p>
      <div className="animate-bounce flex flex-col items-center justify-center">
        <svg
          className="h-8 w-8 text-gray-500 cursor-pointer"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          onClick={() =>
            window.scrollTo({ top: window.innerHeight, behavior: "smooth" })
          }
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </div>
    </div>
  );
}

export default ScrollIndicator;