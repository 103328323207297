import React, { useContext, useEffect } from "react";
import { ThemeContext, themes } from "./ThemeContext";
import { changeAssets } from "./index";
import "./output.css";
import Header from "./Header";
import Projects from "./Projects";
import AboutMe from "./AboutMe";
import Resume from "./Resume";
import Home from "./Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Analytics } from '@vercel/analytics/react'

function App() {
  const { theme, setThemeByName } = useContext(ThemeContext);

  useEffect(() => {
    console.log("Theme changed:", theme);
    changeAssets(theme);
  }, [theme]);

  return (
      <Router>
          <div className={`app ${theme}`}>
              <Header theme={theme} />
              <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/resume" element={<Resume />} />
                  <Route path="/projects" element={<Projects />} />
                  <Route path="/about" element={<AboutMe />} />
              </Routes>
              <footer className="bg-white p-4 text-center w-full">
                  <p>&copy; 2024 Josephine Nguyen. All rights reserved.</p>
              </footer>
          </div>
          <Analytics />
      </Router>
  )
}

export default App;
