import React from "react";
import ScrollIndicator from "./ScrollIndicator";
import ProjectSection from "./ProjectSection";

function Home() {
  return (
    <section>
      <main className="flex flex-col items-center justify-center h-screen text-center p-4 bg-white">
        <h1 className="text-5xl font-bold leading-relaxed">
          Hey there, I’m{" "}
          <span className="bg-primary-100 p-1 rounded-lg">
            Josephine Nguyen
          </span>
          , a{" "}
          <span className="bg-primary-100 p-1 rounded-lg">
            Full-Stack Developer
          </span>{" "}
          passionate about creating{" "}
          <span className="bg-primary-100 p-1 rounded-lg">
            impactful EdTech solutions
          </span>
          !
        </h1>
        <p className="mt-4 text-2xl italic">
          Let's build a brighter future, one line of code at a time!
        </p>
        {/* <div className="mt-8" id="theme-selector">
          <label
            htmlFor="theme-select"
            className="block text-sm font-medium text-gray-700"
          >
            Select Theme:
          </label>
          <select
            id="theme-select"
            value={theme}
            onChange={(e) => setThemeByName(e.target.value)}
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          >
            {themes.map((themeName) => (
              <option key={themeName} value={themeName}>
                {themeName}
              </option>
            ))}
          </select>
        </div> */}
        <ScrollIndicator />
      </main>
      <ProjectSection />
    </section>
  );
}

export default Home;
