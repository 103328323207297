import React from 'react';
import { Link } from 'react-router-dom';

const ProjectCard = ({ title, description, imageUrl, link }) => {
  return (
    <div className="sm:max-w-sm md:max-w-md max-w-64 max-w-rounded-xl my-2 mx-4 overflow-hidden shadow-lg bg-bg-100">
      <img className="w-full" src={imageUrl} alt={title} />
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2">{title}</div>
        <p className="text-gray-700 text-base">{description}</p>
        <Link to={link} className="text-blue-500 hover:underline">Read more</Link>
      </div>
    </div>
  );
};

export default ProjectCard;
