export const projects = [
    {
      id: 1,
      title: 'Project One',
      description: 'This is a description for project one.',
      imageUrl: '/path/to/image1.jpg',
      link: '/project-one',
    },
    {
      id: 2,
      title: 'Project Two',
      description: 'This is a description for project two.',
      imageUrl: '/path/to/image2.jpg',
      link: '/project-two',
    },
    // Add more projects as needed
  ];
  