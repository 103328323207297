import React from "react";

function Resume() {
  return (
    <section>
      <h2>Resume</h2>
      {/* Your resume content */}
    </section>
  );
}

export default Resume;
