import React from "react";

function AboutMe() {
  return (
    <section>
      <h2>About Me</h2>
      {/* Your about me content */}
    </section>
  );
}

export default AboutMe;
