import React, { createContext, useState } from 'react';

const themes = [
  'main',
  'learning',
  'books',
  'painting',
  'crocheting',
  'gardening',
  'origami',
  'coding',
  'dramas',
  'gaming'
];

const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(themes[0]);

  const setThemeByName = (themeName) => {
    if (themes.includes(themeName)) {
      setTheme(themeName);
    } else {
      console.warn(`Theme ${themeName} is not recognized.`);
    }
  };

  return (
    <ThemeContext.Provider value={{ theme, setThemeByName }}>
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeProvider, themes };
